var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-setting-panel" },
    [
      _c(
        "a-tabs",
        {
          attrs: { "default-active-key": _vm.defaultActiveKey },
          on: { change: _vm.callback },
        },
        _vm._l(_vm.menuDataList, function (key, index) {
          return _c("a-tab-pane", { key: index, attrs: { tab: key.name } })
        }),
        1
      ),
      _c(_vm.curComponentName, { tag: "component" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }