var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subScribe" },
    [
      !_vm.isImport
        ? [
            !_vm.isShow
              ? _c(
                  "div",
                  [
                    _c("MonthPicker", {
                      attrs: { "title-text": "个税申报" },
                      on: { change: _vm.initData },
                      model: {
                        value: _vm.month,
                        callback: function ($$v) {
                          _vm.month = $$v
                        },
                        expression: "month",
                      },
                    }),
                    _c("Alert", {
                      attrs: { "show-icon": "", closable: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "提示: 本期计薪-核算薪资环节的 应发工资 全部锁定后，方可进行报送人员统计"
                                  ),
                                ]),
                                _c("a", { on: { click: _vm.skip } }, [
                                  _vm._v("前往本期计薪"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1972907139
                      ),
                    }),
                    _vm._l(_vm.taxList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "content" },
                        [
                          _c(
                            "CbStatistics",
                            {
                              staticClass: "statistics",
                              attrs: {
                                "data-list": item.tableNameList,
                                length: "4",
                                "is-title": true,
                                "is-logo": true,
                                "min-width": 200,
                                "title-width": 240,
                                title: item.companyName,
                                "is-function": true,
                              },
                            },
                            [
                              _c("template", { slot: "right" }, [
                                _c(
                                  "div",
                                  { staticClass: "main-right" },
                                  [
                                    item.checkStatus !== -1
                                      ? _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              prefix: "chakan-chakanmingxi",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.look(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" 查看 ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("template", { slot: "logo" }, [
                                item.checkStatus == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "logo",
                                        style: {
                                          color: "#00A870",
                                          backgroundColor:
                                            "rgba(0,168,112,0.1)",
                                        },
                                      },
                                      [_vm._v(" 申报成功 ")]
                                    )
                                  : _vm._e(),
                                item.checkStatus == 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "logo",
                                        style: {
                                          color: "#ED7B2F",
                                          backgroundColor:
                                            "rgba(237,123,47,0.1)",
                                        },
                                      },
                                      [_vm._v(" 申报处理中 ")]
                                    )
                                  : _vm._e(),
                                item.checkStatus == -1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "logo",
                                        style: {
                                          color: "#5179FB",
                                          backgroundColor:
                                            "rgba(81,121,251,0.1)",
                                        },
                                      },
                                      [_vm._v(" 待申报 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.isShow
              ? _c(
                  "div",
                  [
                    _c("IncomeTax", {
                      attrs: { infos: _vm.list },
                      on: {
                        handleImport: _vm.handleImport,
                        goBack: _vm.goBack,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.isImport
        ? _c("BatchImport", {
            attrs: {
              title: "个税申报批量导入",
              "extra-params": Object.assign({}, _vm.extraParams, {
                taxpayPeriodInfoId: _vm.list.taxpayPeriodInfoId,
              }),
              "import-template": "importTaxpayStaffPaymentInfo",
            },
            on: {
              goBack: function ($event) {
                _vm.isImport = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }