var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payTax" }, [
    _c("div", { staticClass: "navigation" }, [
      _c(
        "div",
        { staticClass: "navigation_button", on: { click: _vm.goBack } },
        [_c("a-icon", { attrs: { type: "left" } })],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.payInfo.proof))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }