var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personnel-report" },
    [
      !_vm.isShow
        ? _c(
            "div",
            [
              _c("MonthPicker", {
                on: { change: _vm.monthChange },
                model: {
                  value: _vm.month,
                  callback: function ($$v) {
                    _vm.month = $$v
                  },
                  expression: "month",
                },
              }),
              _vm.showTip
                ? _c("Alert", {
                    attrs: { "show-icon": "", closable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  "提示: 本期计薪-核算计薪资环节的 应发工资 全部锁定后，方可进行报送人员统计"
                                ),
                              ]),
                              _c(
                                "a",
                                { on: { click: _vm.toSalaryCalculation } },
                                [_vm._v("前往本期计薪")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      618856133
                    ),
                  })
                : _vm._e(),
              _vm.unassignedNum
                ? _c("Alert", {
                    staticClass: "alert-text",
                    attrs: { "show-icon": "", closable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  "有" +
                                    _vm._s(_vm.unassignedNum) +
                                    "名人员暂无报税主体, 有" +
                                    _vm._s(_vm.ignoreStaffNum) +
                                    "名不需要报税"
                                ),
                              ]),
                              _c("a", { on: { click: _vm.setUp } }, [
                                _vm._v("去设置"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2216456127
                    ),
                  })
                : _vm._e(),
              _vm._l(_vm.taxList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "CbStatistics",
                      {
                        staticClass: "statistics",
                        attrs: {
                          "data-list": [],
                          length: "0",
                          "is-title": true,
                          "title-width": 320,
                          "is-function": true,
                        },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "tax-left" }, [
                            _c("div", { staticClass: "tax-left-title" }, [
                              _vm._v(_vm._s(item.companyName)),
                            ]),
                            item.checkStatus !== -1
                              ? _c("div", { staticClass: "tax-left-content" }, [
                                  _vm._v(
                                    "报送人员：" + _vm._s(item.submissionNum)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("template", { slot: "right" }, [
                          _c(
                            "div",
                            { staticClass: "tax-right" },
                            [
                              item.checkStatus === 0
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { prefix: "heduirenyuan-18" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verifyStaff(item, true)
                                        },
                                      },
                                    },
                                    [_vm._v(" 核对人员 ")]
                                  )
                                : _vm._e(),
                              item.checkStatus === 1
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { prefix: "chakan-chakanmingxi" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verifyStaff(item, false)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.isShow
        ? _c(
            "div",
            [
              _c("VerifyStaff", {
                attrs: {
                  info: _vm.taxpaysubjectInfo,
                  "only-read": _vm.onlyRead,
                },
                on: { goBack: _vm.goBack },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("CbIgnore", {
        attrs: {
          visible: _vm.visible,
          title: "无报税主体人员",
          "select-list": _vm.optionList,
          "data-prompt": _vm.staffList,
          "replace-group-key": "taxpaySubjectId",
          "replace-ignore-type-key": "needTaxpaySubject",
          "replace-fields": _vm.optionKeysList,
        },
        on: {
          isIgnore: _vm.isIgnore,
          setPerson: _vm.setPerson,
          cancel: _vm.cancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }